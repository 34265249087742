
import { useStore } from "vuex";
import { computed } from "vue";
import { ActionTypes } from "@/store/action.type";

export default {
  name: "TheHeader",

  setup() {
    const store = useStore();

    function toggleNav() {
      store.dispatch(ActionTypes.TOGGLE_MENU);

      if (store.getters.menuOpen) {
        document.body.classList.add("overflow-y-hidden");
      } else {
        document.body.classList.remove("overflow-y-hidden");
      }
    }

    return {
      menuOpen: computed(() => store.getters.menuOpen),
      toggleNav,
    };
  },
};
