
import helpers from "@/common/helpers";
import { ref } from 'vue';

export default {
  name: "TheCookieNotice",
  setup() {
    const { setCookie } = helpers();
    const hide = ref(true);


    function accept() {
      setCookie("cAccept", "true", 265);
        hide.value = false
    }

    return { accept, hide };
  },
};
